const channelColors: string[] = [
  '#D17373',
  '#A1887F',
  '#8B9684',
  '#9575CD',
  '#7986CB',
  '#455A64',
  '#AED581',
  '#81C784',
  '#4DB6AC',
  '#FFB74D',
  '#64B5F6',
  '#4DD0E1',
]

export {
  channelColors
};
